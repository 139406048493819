import './RegistrationForm.scss';
import * as React from 'react';
import {FormEvent, MutableRefObject, useRef, useState} from 'react';
import {Trans, useTranslation} from 'react-i18next';

import {HeadLineL} from '../../ui/HeadLines/HeadLineL/HeadLineL';
import {TextField, TextFieldType} from '../../ui/Form/TextField/TextField';
import {CheckboxField} from '../../ui/Form/CheckboxField/CheckboxField';
import {DefaultButton} from '../../ui/Buttons/DefaultButton/DefaultButton';
import {EArrowPosition, LinkButton} from '../../ui/Buttons/LinkButton/LinkButton';
import {validateEmail} from '../../../utils/validateEmail';
import {Link} from '../../ui/Link/Link';
import {TFormConfig, useSimpleForm} from '../../hooks/useSimpleForm';

interface IRegistrationForm {
    csrfToken: string
}

const RegistrationForm: React.FC<IRegistrationForm> = ({ csrfToken }) => {
    const { t } = useTranslation();

    const formConfig: TFormConfig = {
        name: {
            value: '',
            validateFn: (value: string) => value.length > 1,
            validateMsg: t('form:field:name:error')
        },
        lastName: {
            value: '',
            validateFn: (value: string) => value.length > 2,
            validateMsg: t('form:field:lastName:error'),
        },
        email: {
            value: '',
            validateFn: (value: string) => {
                const expr = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/u;

                return expr.test(value);
            },
            validateMsg: t('form:field:email:error'),
        },
        password: {
            value: '',
            validateFn: (value: string) => value.length >= 8,
            validateMsg: t('form:field:password:error'),
        },
        optIn: {
            value: false,
            validateFn: null,
            validateMsg: ''
        }
    };

    const { formFields, updateField, updateFormFields, validateFields, anyFieldFailed } = useSimpleForm(formConfig);

    const [ emailInValidation, setEmailInValidaton ] = useState(false);

    const formRef: MutableRefObject<any> = useRef();

    const updateEmail = async (newValue: string): Promise<void> => {
        updateField('email', newValue);
    };

    const onSubmit = (event: FormEvent): void => {
        event.preventDefault();
        validateFields(true);

        const formValid = !anyFieldFailed();

        if (formValid) {
            formRef.current.submit();
        }
    };

    const actions = {
        name: {
            onChange: (newValue: string) => updateField('name', newValue),
        },
        lastName: {
            onChange: (newValue: string) => updateField('lastName', newValue),
        },
        email: {
            onChange: (newValue: string) => updateEmail(newValue),
            onBlur: async () => {
                const currInput = document.activeElement;
                setEmailInValidaton(true);

                validateEmail(formFields['email']).then((res) => {
                    const newState = JSON.parse(JSON.stringify(formFields));
                    newState.emailPass = true;
                    newState.emailErrorMsg = '';

                    updateFormFields(newState);
                    setEmailInValidaton(false);
                }).catch((err) => {
                    const newState = JSON.parse(JSON.stringify(formFields));
                    newState.emailPass = false;
                    newState.emailErrorMsg = err ?? formConfig.email.validateMsg;

                    updateFormFields(newState);
                    setEmailInValidaton(false);
                });

                (currInput as HTMLElement).focus();
            }
        },
        password: {
            onChange: (newValue: string) => updateField('password', newValue),
        },
        optIn: {
            onClick: (): void => {
                if (formFields.optIn) {
                    updateField('optIn', false)
                } else {
                    updateField('optIn', true)
                }
            }
        }
    };

    return (
        <div className="registration-form">
            <div className="registration-form__title">
                <HeadLineL>{t('registerForm:formTitle')}</HeadLineL>
            </div>
            <form
                action={t('paths:api:registrationFormAction')}
                method="POST"
                noValidate
                className="registration-form__submit-form"
                onSubmit={onSubmit}
                ref={formRef}
            >
                <fieldset className="registration-form__user-fields">
                    <div className="registration-form__field-wrap">
                        <TextField
                            name="uname"
                            value={formFields.name}
                            type={TextFieldType.TEXT}
                            actions={actions.name}
                            errorMsg={formFields.nameErrorMsg}
                            readOnly={emailInValidation}
                            placeholder={`${ t('form:field:name:label') }`}
                        />
                    </div>
                    <div className="registration-form__field-wrap">
                        <TextField
                            name="surname"
                            value={formFields.lastName}
                            type={TextFieldType.TEXT}
                            actions={actions.lastName}
                            errorMsg={formFields.lastNameErrorMsg}
                            readOnly={emailInValidation}
                            placeholder={`${ t('form:field:lastName:label') }`}
                        />
                    </div>
                    <div className="registration-form__field-wrap">
                        <TextField
                            name="email"
                            value={formFields.email}
                            type={TextFieldType.EMAIL}
                            actions={actions.email}
                            errorMsg={formFields.emailErrorMsg}
                            readOnly={emailInValidation}
                            placeholder={`${ t('form:field:email:label') }`}
                        />
                    </div>
                    <div className="registration-form__field-wrap">
                        <TextField
                            name="pass"
                            value={formFields.password}
                            type={TextFieldType.PASSWORD}
                            actions={actions.password}
                            errorMsg={formFields.passwordErrorMsg}
                            placeholder={`${ t('form:field:password:label') }`}
                        />
                    </div>
                </fieldset>
                <div className="registration-form__optin-wrap">
                    <>
                        { t('registerForm:iAccept') }&nbsp;
                        <Link href={t('paths:uri:terms')} blank>{t('registerForm:termsAndConditions')}</Link>&nbsp;
                        {t('registerForm:alongWith')} <Link href={t('paths:uri:privacy')} blank>{t('registerForm:privacyPolicy')}</Link><br /><br />
                    </>
                    <CheckboxField
                        name="marketingEmail"
                        checked={formFields.optIn}
                        value="marketing_email"
                        actions={actions.optIn}
                        errorMsg={formFields.optInErrorMsg}
                        disabled={emailInValidation}
                        description={t('form:field:optIn:description')}
                    />
                    <input
                        type="hidden"
                        name="termsAccept"
                        id="termsAccept"
                        value="regulations"
                        readOnly
                        checked
                    />
                    <input
                        type="hidden"
                        name="termsAccept2"
                        id="termsAccept2"
                        value="privacy"
                        readOnly
                        checked
                    />
                </div>
                <input
                    type="hidden"
                    id="_token"
                    name="_token"
                    value={csrfToken}
                />
                <DefaultButton
                    className="registration-form__submit-btn"
                    inForm
                    disabled={emailInValidation}
                >
                    {t('registerForm:createAccount')}
                </DefaultButton>
            </form>
            <div className="registration-form__log-in-teaser">
                {t('registerForm:haveAnAccount')}
                &nbsp;
                <LinkButton url={t('paths:uri:logIn')} small arrowPosition={EArrowPosition.RIGHT}>{t('registerForm:logIn')}</LinkButton>
            </div>
            <span className="registration-form__privacy-info">
                <Trans i18nKey="registerForm:privacyInfo">
                    Admin is ... <Link href="https://www.goldenline.pl/prywatnosc">link</Link>
                </Trans>
            </span>
        </div>
    );
};

export { RegistrationForm };
