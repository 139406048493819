import './RegistrationBox.scss';
import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { WhiteBox } from '../../layout/WhiteBox/WhiteBox';
import { SocialButton, SocialMedia } from '../../ui/Buttons/SocialButton/SocialButton';
import { Separator } from '../../ui/Separator/Separator';
import { RegistrationForm } from '../RegistrationForm/RegistrationForm';

interface IRegistrationBox {
    formCsrfToken: string,
    facebookRegisterUrl: string,
    googleRegisterUrl: string
}

const RegistrationBox: React.FC<IRegistrationBox> = ({ formCsrfToken, facebookRegisterUrl, googleRegisterUrl }) => {
    const { t } = useTranslation();

    return (
        <WhiteBox className="registration-box">
            <div className="registration-box__social-registration">
                <div className="registration-box__button-wrap">
                    <SocialButton
                        url={facebookRegisterUrl}
                        socialMedia={SocialMedia.FACEBOOK}
                    >
                        {t('registerBox:registerWithFacebook')}
                    </SocialButton>
                </div>
                <div className="registration-box__button-wrap">
                    <SocialButton
                        url={googleRegisterUrl}
                        socialMedia={SocialMedia.GOOGLE}
                    >
                        {t('registerBox:registerWithGoogle')}
                    </SocialButton>
                </div>
            </div>
            <Separator className="registration-box__separator">{t('registerBox:or')}</Separator>
            <RegistrationForm csrfToken={formCsrfToken} />
        </WhiteBox>
    );
};

export { RegistrationBox, IRegistrationBox };
